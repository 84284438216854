@font-face {  
  font-family: 'DinBlack';  
  src:  url(./Assets/Fonts/New/DIN\ Black.ttf) format('truetype');
  font-display: block;
}
@font-face {  
  font-family: 'DinBold';  
  src:  url(./Assets/Fonts/New/DIN\ Bold.ttf) format('truetype');
  font-display: block;
}
@font-face {  
  font-family: 'DinLight';  
  src:  url(./Assets/Fonts/New/DIN.ttf) format('truetype');
  font-display: block;
}
@font-face {  
  font-family: 'DinMedium';  
  src:  url(./Assets/Fonts/New/DIN\ Medium.ttf) format('truetype');
  font-display: block;
}
@font-face {  
  font-family: 'DinRegular';  
  src:  url(./Assets/Fonts/DIN-Regular.ttf) format('truetype');
  font-display: block;
} 
@font-face {  
  font-family: 'DinRegularAlternate';  
  src:  url(./Assets/Fonts/DIN-RegularAlternate.ttf) format('truetype');
  font-display: block;
}
body {
  margin: 0;
  font-family:  'DinRegularAlternate', 'DinRegular', 'DinMedium',
    'DinLight', 'DinBold', 'DinBlack';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
