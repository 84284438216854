.Careers-Container{
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    .Careers-Section-1{
        height: 110vh;
        margin: 100px 0px;
        img{
            width: 100%;
            position: absolute;
        }
        .Careers-Content{
            display: flex;
            flex-direction: column;
            display: block;
            position: absolute;
            z-index: 1;
            color: white;
            top: 50%;
            left: 8%;
            font-family: DinLight;
            h1{
                font-family: DinMedium;
                font-size: 40px;
            }
            p{
                width: 600px;
                font-size: 20px;
                margin-top: 30px;
                text-align: justify;
            }
            button {
                background-color: transparent;
                width: 100%;
                max-width: 140px;
                border: 1px solid white;
                margin-right: 20px;
                a{
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
    .Careers-Section-2{
        height: auto;
        display: flex;
        padding: 150px 110px;
        font-family: DinLight;
        justify-content: space-between;
        .Left{
            width: 50%;
            h3{
                font-size: 24px;
                font-family: DinBold;
            }
        }
        .Right{
            width: 50%;
            .Job-Section-Title{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                h3{
                    font-size: 24px;
                    font-family: DinBold;
                }
            }
            button {
                width: 600px;
                padding: 16px 10px;
                display: flex;
                justify-content: flex-start;
                height: 44px;
                align-items: center;
                background-color: white;
                border: none;
                cursor: unset;
                span{
                    font-size: 20px;
                    font-family: DinLight;
                    color: black;
                }
            }
            .margin-20{
                margin-top: 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
            

        }
    }
    .Careers-Section-3{
        height: auto;
        display: flex;
        padding: 15px 110px 100px 110px;
        font-family: DinLight;
        justify-content: space-between;
        .Left{
            width: 50%;
            h2{
                font-family: DinBold;
            }
        }
        .Right{
            width: 50%;
            p{
                font-size: 20px;
                span{
                    display: block;
                    margin-top: 20px;
                }
            }
            .contact-btn{
                color: black;
                font-family: DinLight;
                font-size: 20px;
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
  
    .Careers-Container{
        .Careers-Section-1{
            height: 100vh;
            padding-top: 80px;
            img{
                object-fit: cover;
                height: 100%;
            }
        }
    }
    
  }
@media (max-width: 768px) {
    .Careers-Container{
        .Careers-Section-1{
            padding: 0px;
            img{
                height: 100%;
                object-fit: cover;
            }
            .Careers-Content{
                p{
                    max-width: 300px;
                    font-size: 17px;
                }
            }
        }
        .Careers-Section-2{
            padding: 40px 21px;
            flex-direction: column;
            .Right{
                button{
                    max-width: 300px;
                }
            }
        }
        .Careers-Section-3{
            padding: 50px 21px;
            flex-direction: column;
            .Left{
                width: 100%;
            }
            .Right{
                width: 100%;
                p{
                    font-size: 18px;
                }
            }
        }
    }
}

@media (min-width: 2048px){
    .Careers-Container{
        .Careers-Section-1{
            .Careers-Content{
                h1{
                    font-size: 70px;
                }
                p{
                    width: 700px;
                    font-size: 32px;
                }
            }
        }
        .Careers-Section-2{
            .Left{
                h3{
                    font-size: 48px;
                }
            }
            .Right{
                .Job-Section-Title{
                    h3{
                        font-size: 36px;
                    }
                }
                button{
                    span{
                        font-size: 38px;
                    }
                }
                .margin-20{
                    margin-top: 40px;
                }
            }
        }
        .Careers-Section-3{
            .Left{
                h2{
                    font-size: 50px;
                }
            }
            .Right{
                p{
                    font-size: 36px;
                }
            }
        }
    }
}