.Contact-Container {
    height: 100vh;
    display: flex;
    background-color: white;
    font-family: DinRegular;
    padding: 100px 82px 0px 82px;

    .Contact-Section-1 {
        display: flex;
        justify-content: center;
        width: 100%;
        p{
            font-size: 24px;
        }
        .Contact-Content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .Left {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                span {
                    display: block;
                }
                h1{
                    font-size: 40px;
                }

            }

            .Right {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                .Info-Container {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    span {
                        display: block;
                    }

                    a {
                        width: 425px;
                        padding: 16px 0px;
                        display: flex;
                        justify-content: flex-start;
                        height: 44px;
                        align-items: center;
                        background-color: white;
                        border: none;
                        color: black;
                        text-decoration: none;
                        span {
                            font-size: 20px;
                            font-family: DinLight;
                        }
                    }
                    .margin-20{
                        margin-top: 20px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    }
                    
                }
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .Contact-Container{
    padding: 150px 50px 0px 50px;
    .Contact-Section-1{
        .Contact-Content{
            flex-direction: column;
            .Left{
                height: 65%;
            }
            .Right{
                align-items: flex-start;
                height: 100%;
            }
        }
    }
  }
  }
@media (max-width:768px) {
    .Contact-Container{
        height: auto;
        padding: 100px 21px;
        .Contact-Section-1{
            flex-direction: column;
            p{
                font-size: 14px;
            }
            .Contact-Content{
                flex-direction: column;
                .Right{
                    .Info-Container{
                        width: 100%;
                        a{
                            max-width: 330px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .Contact-Container{
        .Contact-Section-1{
            .Contact-Content{
                .Left{
                    h1{
                        font-size: 70px;
                    }
                    p{
                        font-size: 32px;
                    }
                }
                .Right{
                    align-items: flex-start;
                    width: 50%;
                    .Info-Container{
                        .margin-20{
                            margin-top: 40px;
                        }
                        p{
                            font-size: 34px;
                        }
                        a{
                            width: 600px;
                            
                            span{
                                font-size: 32px;
                            }
                        }
                        span{
                            font-size: 34px;
                        }
                    }
                }
            }
        }
    }
}