.Detail-Container{
    padding: 120px 300px;
    h1{
        font-family: DinBold;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 400px;
        margin: 20px 0px;
    }
    p{
        margin-top: 20px;
        font-family: DinLight;
        font-size: 24px;
    }
}
@media (max-width: 768px) {
    .Detail-Container{
        padding: 100px 25px;
        p{
            font-size: 18px;
        }
    }
}