.Expertise-Container {
    height: auto;
    display: flex;
    flex-direction: column;

    .Expertise-Section-1 {
        height: 100vh;
        padding-top: 100px;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .Expertise-Content {
            font-family: DinLight;
            display: flex;
            flex-direction: column;
            display: block;
            position: absolute;
            color: white;
            top: 50%;
            left: 8%;

            span {
                display: block;
            }

            h1 {
                font-family: DinMedium;
                font-size: 40px;
            }

            p {
                margin-top: 30px;
                font-size: 20px;
            }
        }
    }

    .Expertise-Section-2 {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 200px 110px 100px 110px;
        font-family: DinLight;
        justify-content: flex-end;

        .Expertise-Title {
            h2 {
                font-size: 32px;
            }
        }

        .Line {
            content: "";
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .Expertise-Description {
            width: 100%;
            display: flex;
            padding: 72.5px 0px;

            span {
                display: block;
                margin-top: 20px;
            }

            .Left {
                text-align: justify;
                width: 50%;

                p {
                    font-size: 20px;
                    margin: 0px;
                }
            }

            .Right {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                img {
                    width: 80%;
                }
            }
        }
    }

    .Expertise-Section-3 {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 60px 110px;
        font-family: DinLight;

        .Section-3-Title {
            h2 {
                font-size: 32px;
            }
        }

        .Section-3-Content-Desktop {
            width: 100%;
            display: flex;
            padding: 20px 0px;
            height: 70vh;

            .Button-Section {
                display: flex;
                width: 100%;
                flex-wrap: wrap;

                .margin-25 {
                    margin-top: 25px;
                }

                .active {
                    background-color: black;
                    color: white;
                }

                button {
                    max-width: 335px;
                    width: 335px;
                    padding: 16px 32px;
                    display: flex;
                    justify-content: flex-start;
                    height: 44px;
                    align-items: center;
                    background-color: white;
                    border: 1px solid #000000;
                    border-radius: 4px;
                    margin-right: 20px;

                    span {
                        font-size: 14px;
                        font-family: DinLight;
                    }
                }

                button:active {
                    background-color: black;
                    color: white;
                }
            }

            .Content-Section {
                width: 60%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .Section-3-Content-Mobile {
            display: none;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
  
    .Expertise-Container{
        .Expertise-Section-1{
            padding-top: 80px;
            img{
                object-fit: cover;
            }
        }
        .Expertise-Section-2{
            padding: 150px 50px 0px 50px;
        }
        .Expertise-Section-3{
            padding: 100px 50px;
            .Section-3-Content-Desktop{
                .Button-Section{
                    width: 70%;
                }
            }
        }
    }
  }

@media (max-width: 768px) {
    .Expertise-Container {
        .Expertise-Section-1 {
            padding: 0px;

            img {
                height: 100%;
                object-fit: cover;
            }

            .Expertise-Content {
                padding-right: 21px;
            }
        }

        .Expertise-Section-2 {
            height: auto;
            padding: 40px 21px;

            .Expertise-Description {
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;

                .Right {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }

                .Left {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }

        .Expertise-Section-3 {
            padding: 20px 20px;

            .Section-3-Content-Desktop {
                display: none;
            }

            .Section-3-Content-Mobile {
                display: block;

                .accordion {
                    .accordion-item {
                        margin-bottom: 20px;

                        .accordion-header {
                            .accordion-button:not(.collapsed) {
                                color: white;
                                background-color: black;
                                box-shadow: none;
                            }

                            .accordion-button:not(.collapsed)::after {
                                background-image: none;
                            }

                            .accordion-button:focus {
                                border: 1px solid black;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .Expertise-Container {
        .Expertise-Section-1 {
            .Expertise-Content {
                h1 {
                    font-size: 70px
                }

                p {
                    font-size: 36px;
                }
            }
        }

        .Expertise-Section-2 {
            .Expertise-Title {
                h2 {
                    font-size: 50px;
                }
            }

            .Expertise-Description {
                .Left {

                    p {
                        font-size: 32px;
                    }
                }
            }
        }

        .Expertise-Section-3 {
            .Section-3-Title {
                h2 {
                    font-size: 50px;
                }
            }

            .Section-3-Content-Desktop {
                padding: 40px 0px;
                height: auto;
                .Button-Section {
                    height: 70vh;
                    button {
                        max-width: 600px;
                        width: 600px;
                        height: 70px;
                        span {
                            font-size: 28px;
                        }
                    }
                }
                .Content-Section{
                    width: 40%;
                    h2{
                        font-size: 48px;
                    }
                    p{
                        font-size: 32px;
                    }
                    span{
                        font-size: 32px;
                    }
                    ul{
                        font-size: 32px;
                    }
                }
            }
        }
    }
}