.Nav-Container {
    height: 80px;
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 9999;

    .Nav-Wrapper {
        background-color: white;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 50px;
        font-family: DinRegular;

        .Nav-Left-Section {
            height: 100%;
            display: flex;
            align-items: center;

            a {
                .Logo-Nav {
                    width: 50%;
                }
            }
        }

        .Nav-Right-Section {
            height: 100%;
            display: flex;
            align-items: center;

            .Mobile {
                display: none;
            }

            .Context {
                display: flex;
                .margin-10 {
                    margin-left: 28.5px;
                    font-size: 14px;
                    text-decoration: none;
                    color: black;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .Nav-Container {
        .Nav-Wrapper {
            padding: 10px 21px;
            background-color: white;

            .Nav-Right-Section {
                .Mobile {
                    display: block;
                    font-family: DinLight;

                    Button {
                        background-color: transparent;
                        border: none;

                        .Button-Menu {
                            color: black;
                        }
                        .Button-Close {
                            color: black;
                        }
                    }
                }

                .d-none {
                    display: none;
                }

                .Context {
                    display: none;
                }
            }
        }
    }


    .modal-dialog {
        margin: 0px !important;

        .modal-content {
            border-radius: 0px;
            border: 0px;

            .modal-body {
                height: 100vh;
                background-color: black;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-family: DinLight;

                a {
                    margin: 5px 0px;
                    color: white;
                    text-decoration: none;

                    span {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .Nav-Container{
        height: 150px;
        .Nav-Wrapper{
            padding: 0px 86px;
            .Nav-Left-Section{
                a{
                    .Logo-Nav{
                        width: 80%;
                    }
                }
            }
            .Nav-Right-Section{
                .Context{
                    .margin-10{
                        font-size: 28px;
                    }
                }
            }
        }
    }    
}


@keyframes slide-on {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slide-off {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.show-on {
    animation: slide-on;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(1, 0.885, 0.32, 1);
}

.show-off {
    animation: slide-off;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(1, 0.885, 0.32, 1);
    animation-delay: 0.2s;
}
