.About-Container {
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    .About-Section-1 {
        height: 100vh;
        padding: 100px 0px;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .About-Content{
            display: flex;
            flex-direction: column;
            display: block;
            position: absolute;
            z-index: 1;
            color: white;
            top: 50%;
            left: 8%;
            font-family: DinLight;
            h1{
                font-size: 40px;
                font-family: DinMedium;
                span{
                    display: block;
                }
            }
            p{
                font-size: 20px;
                margin-top: 30px;
            }
        }
    }
    .About-Section-2{
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 200px 110px;
        font-family: DinLight;
        .About-Title{
            h2{
                font-size: 32px;
            }
        }
        .Line{
            content: "";
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
        .About-Description{
            width: 100%;
            display: flex;
            padding-top: 65px;
            span{
             display: block;   
            }
        .Left{
            text-align: justify;
            width: 50%;
            p{
                font-size: 20px;
                margin: 0px;
                span{
                    margin-top: 20px;
                }
            }
        }
        .Right{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            img{
                width: 50%;
            }
            .margin-top-20{
                margin-top: 20px;
            }
        }
        }
    }
}

@media (max-width: 768px){
    .About-Container{
        .About-Section-1{
            padding: 0px;
            img{
                height: 100%;
                object-fit: cover;
            }
            .About-Content{
                p{
                    max-width: 325px;
                }
            }
        }
        .About-Section-2{
            padding: 40px 21px;
            height: auto;
            .About-Description{
                flex-direction: column-reverse;
                padding: 30px 0px;
                .Right{
                    width: 100%;
                    align-items: center;
                    img{
                        width: 100%;
                    }
                }
                .Left{
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
  
    .About-Container{
        .About-Section-1{
            padding: 0px;
            img{
                object-fit: cover;
            }
        }
        .About-Section-2{
            padding: 100px 50px;
            .About-Description{
                .Right{
                    img{
                        width: 75%;
                    }
                }
            }
        }
    }
    
  }
@media (min-width: 2048px) {
    .About-Container{
        .About-Section-1{
            .About-Content{
                h1{
                    font-size: 68px;
                }
                p{
                    font-size: 36px;
                }
            }
        }

        .About-Section-2{
            .About-Title{
                h2{
                    font-size: 46px;
                }
            }
            .About-Description{
                .Left{
                    p{
                        font-size: 36px;
                    }
                }
            }
        }
    }
}