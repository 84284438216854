.loader-wrapper{
    height: 100vh;background-color: black;
}

.loader-wrapper.showup{
    animation: showup 1s cubic-bezier(0.770, 0.000, 0.175, 1.000);

}
.loader-wrapper.showoff{
    animation: showoff 1s cubic-bezier(0.770, 0.000, 0.175, 1.000);

}

@keyframes showup {
    0%{
        transform: translateY(200vh);
    }
    100%{
        transform: translateY(0vh);
    }
}

@keyframes showoff {
    0%{
        transform: translateY(0vh);
    }
    100%{
        transform: translateY(-100vh);
    }
}