.posts-app__pagination{
    button{
        background-color: white;
        color: black;
        margin-right: 20px;
        border-radius: 0px;
        border: 1px solid black;
    }
    button:hover{
        background-color: black;
        color: white;
    }
    .active{
        background-color: black;
        color: white;
    }
}

@media (min-width: 2048px) {
    .posts-app__pagination{
        button{
            width: 60px;
            height: 60px;
        }
    }
}

@media (min-width: 3024px) {
    .posts-app__pagination{
        button{
            width: 80px;
            height: 80px;
            font-size: 36px;
        }
    }
}
